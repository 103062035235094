<template>
    <div class="Login">
        <div class="LoginWrap">
            <div class="loginTop">
                <div class="title">서울시 코로나 <br />확진자 관리시스템</div>
                <div class="title text-center no-pdd-top" v-if="rootUrl == 'http://49.50.172.11:10102'">개발서버</div>
            </div>
            <div class="loginBox">
                <label class="Logintit">아이디</label>
                <div class="inputWrap">
                    <input type="text" v-model="loginUserId" class="loginipt inputWide" placeholder="아이디">
                </div>
                <label class="Logintit">비밀번호</label>
                <div class="inputWrap">
                    <input type="password" v-model="loginUserPw" class="loginipt inputWide" placeholder="비밀번호" v-on:keyup.enter="loginFunc">
                </div>
                <button type="button" class="btnLogin mt20" @click="loginFunc">로그인</button>
                <div class="Logintit2">
                    <label>
                        <input type="checkbox" true-value="Y" false-value="N" v-model="loginAutoChk">
                        아이디 저장
                    </label>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MobileLogin',
        data() {
            return {
                loginUserId:'', // 로그인 아이디
                loginUserPw:'', // 로그인 비밀번호
                loginAutoChk:(this.localStorageGet('userAutoLogin')) ? this.localStorageGet('userAutoLogin') : 'N',    // 자동로그인 여부
            }
        },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
            this.storageClear();
        },
        // $el 생성후
        mounted() {
        },
        methods: {
            loginFunc(){
                if (!this.loginUserId) {
                    this.showAlert('', '아이디 입력 후 진행하세요.');
                    return false;
                }

                if (!this.loginUserPw) {
                    this.showAlert('', '비밀번호 입력 후 진행하세요.');
                    return false;
                }

                let formData = new FormData();
                formData.append('login_id', this.loginUserId);
                formData.append('login_pw', this.loginUserPw);
                formData.append('api_name', 'login');
                this.ajaxPost('', formData,'loginResult');
            },
            loginResult(response){
                let result = response;

                // 정상일 경우
                if (result.result_code == 1){
                    let storage = {};
                    storage.adminId = result.result_data.f_id;
                    storage.adminName = result.result_data.f_admin_name;
                    storage.adminPermission = result.result_data.f_admin_type;
                    storage.adminType = 'MOBILE';

                    this.storageSet('userInfo', storage);
                    this.localStorageSet('userAutoLogin', this.loginAutoChk);
                    this.localStorageSet('userId', result.result_data.f_id);

                    this.$router.push('/mobile/confirmedList');
                }
            },
        }
    }
</script>
<style lang="scss">
@import '../assets/css/mobile.css';
</style>
